<template>
  <div class="box">
    <el-tabs v-if="false" :stretch="true" class="tabs" type="border-card">
      <el-tab-pane class="tabpane" label="账号注册/登录">
        <div class="box-tabs">
          <div class="firstcolumn el-form-item-content">
            <div v-if="active === 3 || active === 4" class="welcome">
              欢迎<span  v-if="!isUserRegist">
            <span class="userloginname"> {{ loginForm.name }} </span>注册!</span>
              <span  v-if="isUserRegist">
              <span class="userloginname"> {{ loginForm.name }} </span>登录!</span>
            </div>
            <el-switch
                v-if="active === 3 || active === 4"
                v-model="loginType"
                :inactive-text="inactiveText"
                active-color="#2285ff"
                class="switch"
                inactive-color="#2285ff"
                @change="switchLoginType"
            />
          </div>
          <el-form
              v-if="active === 0"
              ref="loginForm"
              :model="loginForm"
              :rules="rulesAccount"
              class="form"
              label-width="0px"
          >
            <div
                style="
                display: flex;
                justify-content: left;
                align-items: center;
                margin-right: 10px;
                margin-bottom: 17px;
              "
            >
              <img
                  src="@/assets/image/icon/user.svg"
                  width="18px"
                  style="margin-right: 7px"
              />
              <div>账号</div>
            </div>
            <el-form-item prop="name">
              <el-input
                  v-model="loginForm.name"
                  placeholder="请输入邮箱/手机号"
                  style="width: 360px;"
                  ref="getFocus"
              ></el-input>
            </el-form-item>
            <el-form-item class="form-item-submit">
              <el-button
                  style="width: 360px;margin-top: 7px;"
                  type="primary"
                  @click="submitName('loginForm')"
              >
                下一步
              </el-button>
            </el-form-item>
          </el-form>
          <!-- 邮箱和手机号验证码密码登录 -->
          <el-form
              v-if="active === 1"
              ref="form"
              :model="loginForm"
              class="form"
          >
            <el-form-item label="">
              <div class="el-form-item-content">
                <el-input
                    v-model="loginForm.verificationCode1"
                    class="inline-ipt"
                    placeholder="验证码"
                ></el-input>
                <img
                    :src="require('@/assets/image/login/verificationcode.jpg')"
                    alt=""
                    class="slot-img"
                />
              </div>
            </el-form-item>
            <el-form-item label="">
              <div class="el-form-item-content">
                <el-input
                    v-model="loginForm.verificationCode2"
                    class="inline-ipt-2"
                    placeholder="手机/邮箱验证码"
                    @input="veriCodeInputEvent"
                ></el-input>
                <el-button
                    v-if="codeShow"
                    class="inline-btn"
                    type="primary"
                    @click="sendVeriCode"
                >发送验证码
                </el-button>
                <el-button v-if="!codeShow" class="inline-btn" disabled plain>
                  {{ count }}秒后重试
                </el-button>
              </div>
            </el-form-item>
            <el-form-item class="form-item-submit">
              <el-button
                  id="input"
                  style="width: 360px"
                  type="primary"
                  @click="next"
              >下一步
              </el-button>
            </el-form-item>
          </el-form>

          <el-form
              v-if="active === 2"
              ref="form"
              :model="loginForm"
              class="form"
              label-width="80px"
          >
            <pass-confirm></pass-confirm>
          </el-form>
          <!-- 邮箱和手机号密码登录 -->
          <el-form
              v-if="active === 3"
              ref="passwordform"
              :model="loginForm"
              class="form"
              label-width="0px"
              style="margin-top: 30px"
              :rules="rulesPassword"
          >
            <el-form-item prop="verificationCodeM">
              <div class="el-form-item-content">
                <el-input
                    v-model="loginForm.verificationCodeM"
                    class="inline-ipt"
                    placeholder="验证码"
                ></el-input>
                <div class="slot-img" @click="getCaptcha">
                  <div v-html="captchaImg"></div>
                </div>
              </div>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                  v-model="loginForm.password"
                  style="width: 360px"
                  type="password"
                  placeholder="请输入密码"
              ></el-input>
            </el-form-item>
            <el-form-item class="form-item-submit">
              <el-button
                  v-if="loginDisabled"
                  style="width: 360px;"
                  type="primary"
                  @click="submitLoginByPass('passwordform')"
              >登录
              </el-button>
            </el-form-item>
          </el-form>

          <el-form
              v-if="active === 4"
              ref="vCodeFrom"
              :model="loginForm"
              class="form"
              label-width="0px"
              style="margin-top: 30px"
              :rules="rulesVCode"
          >
            <el-form-item label="" prop="verificationCodeM">
              <div class="el-form-item-content">
                <el-input
                    v-model="loginForm.verificationCodeM"
                    class="inline-ipt"
                    placeholder="验证码"
                ></el-input>
                <div class="slot-img" @click="getCaptcha">
                  <div v-html="captchaImg"></div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="" prop="verificationCodeS">
              <div class="el-form-item-content">
                <el-input
                    v-model="loginForm.verificationCodeS"
                    class="inline-ipt-2"
                    placeholder="手机/邮箱验证码"
                ></el-input>
                <el-button
                    v-if="codeShow"
                    class="inline-btn"
                    type="primary"
                    @click="sendVeriCode"
                >发送验证码
                </el-button>
                <el-button v-if="!codeShow" class="inline-btn" disabled plain>
                  {{ count }}秒后重试
                </el-button>
              </div>
            </el-form-item>
            <el-form-item class="form-item-submit">
              <el-button
                  style="width: 360px"
                  type="primary"
                  @click="submitLoginByCode('vCodeFrom')"
              >登录
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane class="tabpane-2" label="扫码注册/登录" size="200">
        <div>扫码后点击"确认授权"，即可完成账号绑定及登录</div>
        <div class="qrcode" @click="updateQrcode">
          <template v-if="qrTimeout">
            <div class="qrcode-mask">
              <font-awesome-icon class="refresh-icon" icon="sync-alt"/>
              <p style="position: absolute; padding-top: 80px; color: white">
                二维码过期，点击刷新
              </p>
            </div>
          </template>
          <vue-qr :text="code" @click="updateQrcode"/>
        </div>
        <span>微信 / App</span>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import VueQr from 'vue-qr';
import Axios from 'axios';
import { PassConfirm } from '@/components/console/Index';

export default {
  data() {
    const validateName = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/;
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (value === '') {
        callback(new Error('请输入账号'));
      } else if (!(phoneReg.test(value) || mailReg.test(value))) {
        callback(new Error('请输入正确的手机号/邮箱'));
      } else if (mailReg.test(value)) {
        this.loginAskType = 'EMAIL';
        callback();
      } else {
        callback();
      }
    };
    return {
      loginAskTypeSuffix: 'PASSWORD',
      webSocketId: '',
      isUserRegist: false, // 用户是否已经注册0否 1是
      loginAskType: 'PHONE', // 0错误1手机号2邮箱
      userinfo: {},
      name: 'Console',
      websocketObj: null,
      qrTimeout: false,
      enablePing: true,
      captchaImg: '',
      code: 'ulla,ella,vita,smita',
      reconnectTime: 0,
      maxConnect: 100,
      loginForm: {
        name: '',
        password: '',
        verificationCodeM: '',
        verificationCodeS: '',
      },
      isVerificationPass: false,
      url:
          'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      active: 0, // 0:填账号页面 1:填验证码注册页面  2:填密码注册页面 3:填密码登录页面 4:填验证码登录页面
      loginType: true, // 登录方式 true:密码，false:验证码
      inactiveText: '密码登录',
      // backgroundImage: require('@/assets/image/login/background.webp'),
      websocketUrl: 'wss://service.yuhu.tech/ws/',
      // websocketUrl: 'ws://192.168.193.98:3000/ws/',
      codeShow: true, // 判断显示隐藏
      count: '', // 显示时的文字内容
      timer: null,
      loginDisabled: true,
      rulesAccount: {
        name: [{
          validator: validateName,
          trigger: 'blur',
        }],
      },
      rulesPassword: {
        verificationCodeM: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur',
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur',
        }],
      },
      rulesVCode: {
        verificationCodeM: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur',
        }],
        verificationCodeS: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur',
        }],
      },
    };
  },
  watch: {
    loginType() {
      if (this.loginType === true) {
        this.active = 3;
        this.inactiveText = '密码登录';
        this.loginAskTypeSuffix = 'PASSWORD';
      } else {
        this.active = 4;
        this.inactiveText = '验证码登录';
        this.loginAskTypeSuffix = 'CODE';
      }
    },
  },
  components: {
    PassConfirm,
    VueQr,
  },
  mounted() {
    this.loadingFullScreen();
    this.initWebsocket();
    this.$nextTick(() => {
      this.$refs.getFocus.focus();
    });
  },
  destroyed() {
    this.onClose();
  },
  methods: {
    initWebsocket() {
      if ('WebSocket' in window) {
        console.log('websocket created!');
        console.log(this.websocketObj);
        this.webSocketObj = new WebSocket(this.websocketUrl);
        this.webSocketObj.onmessage = this.onMessage;
        this.webSocketObj.onopen = this.onOpen;
        this.webSocketObj.onerror = this.onError;
        this.webSocketObj.onclose = this.onClose;
      }
    },
    query(obj) {
      // 首先判断obj是否为真，为真则进行处理，不然直接return
      if (obj) {
        // 定义变量接收query字符串
        let query = '';
        // 循环遍历对象
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const i in obj) {
          // 定义变量接收对象的value值
          let value = obj[i];
          // 若对象的value值为数组，则进行join打断
          if (Array.isArray(value)) {
            value = value.join(',');
          }
          // 进行字符串拼接
          query += `&${i}=${value}`;
        }
        // replace返回一个新的字符串，要用query重新接受一下，并把第一个&替换为?
        query = query.replace('&', '?');
        // 返回生成的query字符串
        return query;
      }
      return '';
    },
    loadingFullScreen() {
      this.$loading({
        lock: true,
        text: '正在为您加载，请稍后...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
    },
    async switchTokenToLogin(webSocketId) {
      const paasUrl = 'https://console.yuhu.tech';
      const passToken = await Cookies.get('passToken', { path: '/', domain: '.yuhu.tech' });
      if (passToken != null) {
        // 换取本项目 token localStorage.setItem('token', officialSiteToken);
        console.log(passToken);
        try {
          const { data } = await Axios.post('/auth/swapAuthToken', {
            swapToken: passToken,
            callbackId: webSocketId,
          });
          console.log(data);
          if (!data.data.success) {
            window.location = `${paasUrl}/login?referer=https://www.yuhu.tech/console/login`;
          }
        } catch (error) {
          this.$message.error(`抱歉，暂时无法连接到服务器，请稍后再试${error}`);
        }
      } else {
        console.log('no token found');
        window.location = `${paasUrl}/login?referer=https://www.yuhu.tech/console/login`;
      }
    },
    onOpen() {
      if (this.webSocketObj.readyState === 1) {
        // - readyState 1 connected
        this.updateQrcode();
        this.getCaptcha();
        this.heartbeat();
      }
    },
    updateQrcode() {
      this.webSocketObj.send(JSON.stringify({ event: 'qrcode' }));
      this.qrTimeout = false;
    },
    getCaptcha() {
      this.webSocketObj.send(JSON.stringify({ event: 'graphicVeriCode' }));
    },
    heartbeat() {
      if (this.enablePing) {
        this.webSocketObj.send(JSON.stringify({ event: 'heartbeat' }));
        setTimeout(() => {
          console.log('Pong!');
          this.heartbeat();
        }, 13000);
      }
    },
    onMessage(evt) {
      const receivedMsg = evt && JSON.parse(evt.data);
      console.log(receivedMsg);
      if (receivedMsg) {
        if (receivedMsg.event === 'qrcode') {
          this.code = `https://service.yuhu.tech/search?qrcode=${encodeURIComponent(
            JSON.stringify({
              id: receivedMsg.id,
              code: receivedMsg.code,
            }),
          )}`;
          // this.code = 'https://service.yuhu.tech/search?qrcode=123223';
        } else if (receivedMsg.payload && receivedMsg.event === 'login') {
          localStorage.setItem('token', receivedMsg.payload);
          localStorage.setItem('userId', receivedMsg.userInfo.id);
          localStorage.setItem('userName', receivedMsg.userInfo.name);
          localStorage.setItem('userPhone', receivedMsg.userInfo.phone);
          Cookies.set('userName', receivedMsg.userInfo.name, { path: '/', domain: 'localhost' });
          Cookies.set('userName', receivedMsg.userInfo.name, { path: '/', domain: '.yuhu.tech' });
          const { redirect, ...rest } = this.$route.query;
          console.log('redirect', this.$route.query);
          if (redirect != null) {
            const queryString = this.query(rest);
            window.location.href = `${redirect}${queryString}`;
          } else {
            window.location.href = '/';
          }
          // this.$router.push('/');
        } else if (receivedMsg.event === 'graphicVeriCode') {
          this.captchaImg = receivedMsg.data.img;
        } else if (receivedMsg.event === 'verifyGraphicCode') {
          if (receivedMsg.data.success === 'true') {
            // 点击获取验证码
            const TIME_COUNT = 60; // 倒计时60秒
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.codeShow = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count -= 1;
                } else {
                  this.codeShow = true;
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          } else {
            this.$message.error('验证码错误，请重新输入');
          }
        } else if (
          receivedMsg.event === 'login'
            && receivedMsg.success === false
        ) {
          this.$message.error('登录失败');
        } else if (receivedMsg.event === 'userConnection') {
          this.switchTokenToLogin(receivedMsg.id);
          this.webSocketId = receivedMsg.id;
        }
      }
    },
    onError() {
      // 链接失败，进行重连
      if (this.reconnectTime <= this.maxConnect) {
        setTimeout(() => {
          this.onOpen();
          this.reconnectTime += 1;
        }, 5000);
      } else {
        if (this.$route.name === 'login') {
          this.$message.error('抱歉，暂时无法连接到服务器，请稍后再试');
        }
        this.onClose();
      }
    },
    onClose() {
      this.webSocketObj.close();
    },
    switchLoginType() {
      if (this.active === 3) {
        this.loginType = false;
        this.active = 4;
      } else if (this.active === 4) {
        this.loginType = true;
        this.active = 3;
      }
    },
    submitName(formName) {
      this.$refs[formName].validate((valid) => {
        console.log(valid);
        if (valid) {
          const url = '/user/getUserInfo';
          const data = {
            queryMethod: this.loginAskType,
            uniqueIdentifier: this.loginForm.name,
            ententerprisId: 1,
          };
          console.log(data);
          Axios.post(url, data)
            .then((response) => {
              if (response.data.success) {
                console.log(response.data.data);
                if (response.data.data && response.data.data.name) {
                  this.userinfo = response.data.data;
                  this.isUserRegist = true;
                } else {
                  this.userinfo.name = '登录';
                }
              } else {
                this.userinfo.name = '新用户注册';
              }
              this.active = 3;
            })
            .catch((error) => {
              this.$message.error(`抱歉，暂时无法连接到服务器，请稍后再试${error}`);
            });
        } else {
          console.log('error submit!!');
          return false;
        }
        return true;
      });
    },
    submitLoginByPass(formName) {
      this.$refs[formName].validate((valid) => {
        console.log(valid);
        if (valid) {
          const url = '/auth/callbackWeb';
          const data = {
            loginMethod: this.loginAskType + this.loginAskTypeSuffix,
            ententerprisId: 1,
            loginCode: this.loginForm.verificationCodeM,
            id: this.webSocketId,
            loginMethodObject: JSON.stringify({
              account: this.loginForm.name,
              password: this.loginForm.password,
            }),
            uniqueIdentifier: this.loginForm.name,
          };
          console.log(data);
          Axios.post(url, data)
            .then((response) => {
              if (response.data.success) {
                console.log(response.data.data);
                this.$message.success('登录成功等待跳转');
              } else {
                this.webSocketObj.send(JSON.stringify({ event: 'graphicVeriCode' }));
                this.$message.error(`${response.data.message}`);
              }
              this.active = 3;
            })
            .catch((error) => {
              this.$message.error(`抱歉，暂时无法连接到服务器，请稍后再试${error}`);
            });
        }
      });
    },
    submitLoginByCode(formName) {
      this.$refs[formName].validate((valid) => {
        console.log(valid);
        if (valid) {
          const url = '/auth/callbackWeb';
          const data = {
            loginMethod: this.loginAskType + this.loginAskTypeSuffix,
            ententerprisId: 1,
            loginCode: this.loginForm.verificationCodeM,
            id: this.webSocketId,
            loginMethodObject: JSON.stringify({
              account: this.loginForm.name,
              verificationCode: this.loginForm.verificationCodeS,
            }),
            uniqueIdentifier: this.loginForm.name,
          };
          console.log(data);
          Axios.post(url, data)
            .then((response) => {
              if (response.data.success) {
                console.log(response.data.data);
                this.$message.success('登录成功等待跳转');
              } else {
                this.webSocketObj.send(JSON.stringify({ event: 'graphicVeriCode' }));
                this.$message.error(`${response.data.message}`);
              }
            })
            .catch((error) => {
              this.$message.error(`抱歉，暂时无法连接到服务器，请稍后再试${error}`);
            });
        }
      });
    },
    veriCodeInputEvent(content) {
      console.log(content);
      console.log(typeof content);
      if (content !== '') {
        this.loginDisabled = false;
      }
    },
    next() {
      // 判断this.loginForm.name是否是新账号
      if (this.loginForm.name === 'new') {
        // 如果是新账号，进入注册页面
        this.active += 1;
      } else if (this.active === 0) {
        this.active = 3;
      } else {
        this.active += 1;
      }
    },
    sendVeriCode() {
      if (this.loginForm.verificationCodeM !== '') {
        const url = '/auth/sendCode';
        const data = {
          loginMethod: this.loginAskType + this.loginAskTypeSuffix,
          uniqueIdentifier: this.loginForm.name,
          ententerprisId: 1,
          loginCode: this.loginForm.verificationCodeM,
          id: this.webSocketId,
        };
        console.log(data);
        Axios.post(url, data)
          .then((response) => {
            if (response.data.success) {
              const TIME_COUNT = 60; // 倒计时60秒
              if (!this.timer) {
                this.count = TIME_COUNT;
                this.codeShow = false;
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count -= 1;
                  } else {
                    this.codeShow = true;
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000);
              }
            } else {
              this.$message.error(response.data.message);
            }
          })
          .catch((error) => {
            this.$message.error(`抱歉，暂时无法连接到服务器，请稍后再试${error}`);
          });
      } else {
        this.$message.error('未输入验证码');
      }
    },
  },
};
</script>

<style scoped>
.box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.05);
  background-size: cover;
  background-image: url('~@/assets/image/login/background.webp');
}

.tabs {
  height: 335px;
  width: 450px;
  margin-right: 10%;
  border-radius: 10px;
}

.box-tabs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 360px;
  height: 247px;
}

.el-tabs {
  border: 0;
}

.el-tabs >>> .el-tabs__item:last-child {
  border-top-right-radius: 12px;
  border-right: 0;
}

.el-tabs >>> .el-tabs__item:first-child {
  border-top-left-radius: 12px;
  border-left: 0;
}

.el-tabs >>> .el-tabs__item {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
}

.el-tabs >>> .el-tabs__header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
}

.el-tabs >>> .el-tabs__item.is-active {
  border-bottom-color: #409eff;
  border-bottom-width: 2px;
}

.el-tabs--border-card {
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
}

.tabpane {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.form {
  width: 100%;
  text-align: left;
}

.inline-ipt {
  width: 56%;
}

.inline-ipt-2 {
  width: 56%;
}

.inline-btn {
  width: 39%;
}

.slot-img {
  height: 40px;
  width: 39%;
  background: #000;
}

.el-form-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-item-submit > .el-form-item__content {
  margin-left: 0 !important;
}

.tabpane-2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.qrcode {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qrcode-mask {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  opacity: 0.8;
  width: 180px;
  height: 180px;
}

.el-switch >>> .el-switch__label.is-active {
  color: black;
}

.refresh-icon {
  width: 40px;
  height: 40px;
  color: white;
}
.firstcolumn{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.userloginname{
  font-size: 14px;
  color: #66B1FF;

}
.welcome {
  display: inline;
  top: 16px;
  left: 8px;
  font-size: 14px;
}
</style>
